import React from 'react';

/**
 *
 *
 * @return {*}
 */
function Courses() {
  return (
    <div />
  );
}

export default Courses;
